import React from 'react';

const TiktokIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18">
      <path d="M15 7.095a6.975 6.975 0 0 1-4.076-1.304v5.935c-.002 4.154-4.5 6.75-8.098 4.67-3.597-2.078-3.595-7.272.004-9.348a5.393 5.393 0 0 1 3.438-.67v2.984c-1.82-.572-3.576 1.04-3.162 2.901.414 1.862 2.688 2.578 4.093 1.288.512-.47.803-1.131.803-1.825V.125h2.922c-.002.247.018.493.061.736a4.057 4.057 0 0 0 1.79 2.664c.66.436 1.434.669 2.225.668v2.902Z" />
    </svg>
  );
};

export default TiktokIcon;