import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import { H3 } from '../PageBuilder/Primitives/Heading';
import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './ImpressumPage.duck';
import css from './ImpressumPage.module.css';



const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

const FaqContent = props => {
  return (
    <div className={css.container}>
      <H3 as="h2" className={css.title}>
        Häufig gestellte Fragen (FAQs)
      </H3>
      <p>
        <strong>
          Wie viel kostet es, mein Auto auf dealgo.ch zu inserieren?
        </strong>
        <br />
        Das Inserieren Ihres Autos auf dealgo.ch ist völlig kostenlos.
      </p>
      <p>
        <strong>
          Wie funktioniert der Matching-Prozess?
        </strong>
        <br />
        dealgo.ch verwendet ein einzigartiges System, um Ihr Auto mit potenziellen Käufern abzugleichen, die Interesse an dieser Art von Fahrzeug haben. Anhand der Informationen in Ihrer Anzeige werden passende Käufer in unserer Datenbank identifiziert und benachrichtigt.
      </p>
      <p>
        <strong>
          Wie werde ich von interessierten Käufern kontaktiert?
        </strong>
        <br />
        Interessierte Käufer können Sie direkt über die von Ihnen in Ihrer Anzeige angegebene Kontaktmethode (Telefon, E-Mail) kontaktieren. dealgo.ch wickelt den Verkauf selbst nicht ab.
      </p>
      <p>
        <strong>
          Kann ich den Verkaufspreis in meiner Anzeige verhandeln?
        </strong>
        <br />
        Ja, der Verkaufspreis ist eine Verhandlungssache zwischen Ihnen und dem potenziellen Käufer.
      </p>
      <p>
        <strong>
          Muss ich mein Auto jemandem verkaufen, der mich über dealgo.ch kontaktiert?
        </strong>
        <br />
        Nein, Sie sind nicht verpflichtet, Ihr Auto an jemanden zu verkaufen, der Sie kontaktiert. Sie können entscheiden, ob Sie mit dem potenziellen Käufer verhandeln möchten.
      </p>
      <p>
        <strong>
          Wie finde ich ein Auto auf dealgo.ch?
        </strong>
        <br />
        dealgo.ch ist derzeit kein Marktplatz, auf dem Sie direkt nach Autos suchen können. Sie können jedoch Ihr Interesse an bestimmten Autotypen registrieren via info@dealgo.ch. Wenn ein entsprechendes Inserat eingestellt wird, werden Sie benachrichtigt.
      </p>
      <p>
        <strong>
          Was kann ich tun, wenn ich an einem auf dealgo.ch inserierten Auto interessiert bin?
        </strong>
        <br />
        Wenn Sie uns zusätzlich Ihr Interesse an einem bestimmten Fahrzeugtyp mitteilen möchten, können Sie sich per E-Mail an info@dealgo.ch wenden. Sollten passende Angebote hinzukommen, werden wir prüfen, ob eine Kontaktaufnahme mit Ihnen möglich ist. Bitte beachten Sie jedoch, dass dies keine Garantie auf die Zusendung von Informationen darstellt.
      </p>
      <p>
        <strong>
          Warum bieten wir (noch) keinen Direktkauf an?
        </strong>
        <br />
        Der Fokus von dealgo.ch liegt aktuell darauf, Verkäufer und passende Käufer schnell und einfach zusammenzuführen. Wir arbeiten kontinuierlich an der Weiterentwicklung unserer Plattform und können uns vorstellen, in Zukunft auch Funktionen für den Direktkauf anzubieten.
        <br />
        Sie können im Moment leider nicht direkt  über dealgo.ch kaufen. Um die Privatsphäre der Verkäufer zu schützen, zeigen wir auf der Plattform keine persönlichen info und wickeln den Verkauf nicht selbst ab.
      </p>
      <p>
        <strong>
          Kann ich den Kaufpreis mit dem Verkäufer verhandeln?
        </strong>
        <br />
        Ja, der Verkaufspreis ist in der Regel Verhandlungssache zwischen Ihnen und dem Verkäufer.
      </p>
      <p>
        <strong>
          Bietet dealgo.ch einen Käuferschutz an?
        </strong>
        <br />
        Nein, dealgo.ch bietet derzeit keinen Käuferschutz an. Die Verkaufsabwicklung erfolgt direkt zwischen Ihnen und dem Verkäufer.
      </p>
      <p>
        <strong>
          Ist dealgo.ch in der ganzen Schweiz verfügbar?
        </strong>
        <br />
        Ja, dealgo.ch ist in der ganzen Schweiz verfügbar.
      </p>
      <p>
        <strong>
          Welche Art von Autos kann ich auf dealgo.ch verkaufen?
        </strong>
        <br />
        Sie können so ziemlich jede Art von Auto auf dealgo.ch inserieren, solange es sich um ein Privatverkauf handelt.
      </p>
      <p>
        <strong>
          Ist dealgo.ch kostenlos nutzbar?
        </strong>
        <br />
        Ja, die Nutzung von dealgo.ch ist kostenlos.
      </p>
      <p>
        <strong>
          Zusätzliche Punkte
        </strong>
        <br />
        Sie können in Ihren FAQs auch erwähnen, dass sich dealgo.ch noch in der Entwicklung befindet und sich das Angebot in Zukunft weiterentwickeln könnte.
      </p>
    </div>
  )
}

const ImpressumContent = props => {
  return (
    <div className={css.container}>
      <H3 as="h2" className={css.title}>
        Impressum
      </H3>
      <p>Handelsregistereintrag Eingetragener Firmenname: dealgo.ch by ElieKhudari
        Haftungsausschluss für Links Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs.</p>
      <p>
        Es wird jegliche Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des Benutzers.
        <br />
        Urheberrechte Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf dieser Website gehören ausschliesslich Eliekhudari oder den speziell genannten Rechteinhabern.
      </p>
      <p>Für die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung des Urheberrechtsträgers im Voraus einzuholen.</p>

      <p>
        Kontakt adresse
        <br />
        Wintherthurerstrasse, 8006 Zurich, Switzerland
        <br />
        Impressum Authorized representative(s) Elie khudari
        <br />
        <a href='mailto:info@dealgo.ch'>info@dealgo.ch</a>
      </p>
    </div>
  )
}

const ImpressumPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;
  return (
    <PageBuilder
      pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
    />
  );
};

ImpressumPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ImpressumPage = compose(connect(mapStateToProps))(ImpressumPageComponent);

const IMPRESSUM_POLICY_ASSET_NAME = ASSET_NAME;
export { IMPRESSUM_POLICY_ASSET_NAME, ImpressumPageComponent, FaqContent, ImpressumContent };

export default ImpressumPage;