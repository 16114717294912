import React, { useState } from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';

import { LinkedLogo, NamedLink, ExternalLink, Modal } from '../../../../components';
import FacebookIcon from './icons/FacebookIcon';
import InstagramIcon from './icons/InstagramIcon';
import TiktokIcon from './icons/TiktokIcon';
import YoutubeIcon from './icons/YoutubeIcon';

import Field from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionFooter.module.css';
import { getIsDealer } from '../../../../util/userUtils';
import { propTypes } from '../../../../util/types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { manageDisableScrolling } from '../../../../ducks/ui.duck';
import { TermsOfServiceContent } from '../../../TermsOfServicePage/TermsOfServicePage';

// We need to get PrivacyPolicy asset and get it rendered for the modal on this page.
import { PrivacyPolicyContent } from '../../../PrivacyPolicyPage/PrivacyPolicyPage';

import LogoImage from './icons/logo.png';
import { ImpressumContent } from '../../../ImpressumPage/ImpressumPage';
import { FaqContent } from '../../../ImpressumPage/ImpressumPage';

const GRID_CONFIG = [
  { contentCss: css.contentCol1, gridCss: css.gridCol1 },
  { contentCss: css.contentCol2, gridCss: css.gridCol2 },
  { contentCss: css.contentCol3, gridCss: css.gridCol3 },
  { contentCss: css.contentCol4, gridCss: css.gridCol4 },
];
const MAX_MOBILE_SCREEN_WIDTH = 1024;

const getIndex = numberOfColumns => numberOfColumns - 1;

const getContentCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.contentCss : GRID_CONFIG[0].contentCss;
};

const getGridCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.gridCss : GRID_CONFIG[0].gridCss;
};

const KEY_CODE_ENTER = 13;

// Section component that's able to show blocks in multiple different columns (defined by "numberOfColumns" prop)
const SectionFooter = props => {
  const {
    sectionId,
    className,
    rootClassName,
    numberOfColumns,
    socialMediaLinks,
    slogan,
    appearance,
    copyright,
    blocks,
    options,
    linkLogoToExternalSite,
    currentUser,
    onManageDisableScrolling
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const linksWithBlockId = socialMediaLinks?.map(sml => {
    return {
      ...sml,
      blockId: sml.link.platform,
    };
  });

  const showSocialMediaLinks = socialMediaLinks?.length > 0;
  const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
  const isMobileLayout = hasMatchMedia
    ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
    : true;
  const logoLayout = isMobileLayout ? 'mobile' : 'desktop';

  // use block builder instead of mapping blocks manually
  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [impressumModalOpen, setImpressumModalOpen] = useState(false);
  const [faqModalOpen, setFaqModalOpen] = useState(false);

  const handleClick = callback => e => {
    e.preventDefault();
    callback(e);
  };
  const handleKeyUp = callback => e => {
    // Allow click action with keyboard like with normal links
    if (e.keyCode === KEY_CODE_ENTER) {
      callback();
    }
  };

  const isDealer = getIsDealer(currentUser);

  return (
    <SectionContainer
      as="footer"
      id={sectionId}
      className={className || css.root}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.footer}>
        {/* <div className={classNames(css.content, getContentCss(numberOfColumns))}> */}
        <div className={css.content}>
          <div className={css.linksContainer}>
            <p style={{ marginBottom: 0, paddingBottom: 0 }}>
              <FormattedMessage id="Footer.title1" />
              <img src={LogoImage} className={css.logoImage} />
            </p>
            {/* <LinkedLogo
              rootClassName={css.logoLink}
              logoClassName={css.logoWrapper}
              logoImageClassName={css.logoImage}
              linkToExternalSite={linkLogoToExternalSite}
              layout={logoLayout}
            /> */}
            <div className={css.contactLinks}>
              Winterthurerstrasse <br />
              8006 Zürich <br />
              Schweiz <br />
              <a href="mailto: info@dealgo.ch">info@dealgo.ch</a>
              {isDealer &&
                <a href="mailto: kontakt@dealgo.ch">kontakt@dealgo.ch</a>
              }
            </div>
            <div style={{ marginTop: 21 }}>
              <FormattedMessage id="Footer.subtitle" />
              <div className={css.icons}>
                {/* <ExternalLink href='https://instagram.com/@dealgo.ch'> */}
                <ExternalLink href='https://www.instagram.com/dealgo.ch?igsh=Nm9lZHZuZmc3c2Y2 '>
                  <InstagramIcon />
                </ExternalLink>
                <ExternalLink href='https://www.tiktok.com/@dealgo.ch?_t=8n3s3GVCzei&_r=1'>
                  <TiktokIcon />
                </ExternalLink>
                <ExternalLink href='https://www.facebook.com/share/2UVvwtDRL2xf1Egf/'>
                  <FacebookIcon />
                </ExternalLink>
                <ExternalLink href='https://youtube.com/@dealgo-ch'>
                  <YoutubeIcon />
                </ExternalLink>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
            <div className={css.content}>
              <div className={css.linksContainer}>
                <p>
                  <FormattedMessage id="Footer.title2" />
                </p>

                <div className={css.contactLinks}>
                  <NamedLink className={css.link} name="AboutUsPage">
                    <FormattedMessage id="Footer.aboutUs" />
                  </NamedLink>
                  <NamedLink className={css.link} name="NewListingPage">
                    <FormattedMessage id={`Footer.text2`} />
                  </NamedLink>
                  {/* <NamedLink name="ContactPage"> */}
                  <ExternalLink className={css.link} href="https://dealgo.ch/p/kontakt" target="_self" >
                    <FormattedMessage id="Footer.contact" />
                  </ExternalLink>
                  {/* </NamedLink> */}
                  <NamedLink className={css.link} name="SignupDealerPage">
                    <FormattedMessage id="Footer.autohandler" />
                  </NamedLink>
                </div>
              </div>
              <div className={css.linksContainer}>
                <p>
                  <FormattedMessage id="Footer.title3" />
                </p>

                <div className={css.contactLinks}>
                  <a
                    className={css.privacyLink}
                    onClick={handleClick(() => setTosModalOpen(true))}
                    role="button"
                    tabIndex="0"
                    onKeyUp={handleKeyUp(() => setTosModalOpen(true))}
                  >
                    <FormattedMessage id="Footer.TermsLinkText" />
                  </a>
                  <a
                    className={css.privacyLink}
                    onClick={handleClick(() => setPrivacyModalOpen(true))}
                    role="button"
                    tabIndex="0"
                    onKeyUp={handleKeyUp(() => setPrivacyModalOpen(true))}
                  >
                    <FormattedMessage id="Footer.PrivacyLinkText" />
                  </a>
                  <a
                    className={css.privacyLink}
                    onClick={handleClick(() => setImpressumModalOpen(true))}
                    role="button"
                    tabIndex="0"
                    onKeyUp={handleKeyUp(() => setImpressumModalOpen(true))}
                  >
                    <FormattedMessage id="Footer.impressum" />
                  </a>
                  <a
                    className={css.privacyLink}
                    onClick={handleClick(() => setFaqModalOpen(true))}
                    role="button"
                    tabIndex="0"
                    onKeyUp={handleKeyUp(() => setFaqModalOpen(true))}
                  >
                    <FormattedMessage id="Footer.faq" />
                  </a>
                </div>
              </div>
            </div>
            <p style={{ color: "var(--colorWhite)", marginBottom: 0, paddingBottom: 0 }}>
              © {new Date().getFullYear()} dealgo.ch Alle Rechte vorbehalten
            </p>
          </div>

          <Modal
            id="Footer.tos"
            isOpen={tosModalOpen}
            onClose={() => setTosModalOpen(false)}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.termsWrapper}>
              <TermsOfServiceContent />
            </div>
          </Modal>
          <Modal
            id="Footer.privacyPolicy"
            isOpen={privacyModalOpen}
            onClose={() => setPrivacyModalOpen(false)}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.privacyWrapper}>
              <PrivacyPolicyContent />
            </div>
          </Modal>
          <Modal
            id="Footer.impressum"
            isOpen={impressumModalOpen}
            onClose={() => setImpressumModalOpen(false)}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.privacyWrapper}>
              <ImpressumContent />
            </div>
          </Modal>
          <Modal
            id="Footer.faq"
            isOpen={faqModalOpen}
            onClose={() => setFaqModalOpen(false)}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.privacyWrapper}>
              <FaqContent />
            </div>
          </Modal>
          {/* <div className={css.sloganMobile}>
            <Field data={slogan} className={css.slogan} />
          </div>
          <div className={css.detailsInfo}>
            <div className={css.sloganDesktop}>
              <Field data={slogan} className={css.slogan} />
            </div>
            {showSocialMediaLinks ? (
              <div className={css.icons}>
                <BlockBuilder blocks={linksWithBlockId} sectionId={sectionId} options={options} />
              </div>
            ) : null}
            <Field data={copyright} className={css.copyright} />
          </div>
          <div className={classNames(css.grid, getGridCss(numberOfColumns))}>
            <BlockBuilder blocks={blocks} sectionId={sectionId} options={options} />
          </div> */}
        </div>
      </div>
    </SectionContainer >
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
  currentUser: null,
};

SectionFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,
  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
  currentUser: propTypes.currentUser,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SectionFooter);

